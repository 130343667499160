<template>
  <div class="editPassword" >
    <div class="self-con">
      <div class="password-title">密码修改</div>
      <el-form :model="ruleForm2" ref="ruleForm2" label-width="120px" label-position="left" :rules="rules" class="password-form">
        <el-form-item prop="oldPass" label="旧密码：">
          <el-input type="password" v-model="ruleForm2.oldPass" auto-complete="off" suffix-icon="el-icon-user-solid"></el-input>
        </el-form-item>
        <el-form-item prop="newPass" label="新密码：">
          <el-input type="password" v-model="ruleForm2.newPass" auto-complete="off" suffix-icon="el-icon-lock"></el-input>
        </el-form-item>
        <el-form-item  prop="newPass2" label="确认新密码：">
          <el-input type="password" v-model="ruleForm2.newPass2" auto-complete="off" suffix-icon="el-icon-lock"></el-input>
        </el-form-item>
      </el-form>
      <div class="btn">
        <el-button type="info" @click="resetForm" class="cancel">取消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm2')" class="affirm">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: "editPassword",
  data() {
    var validatePass = (rule, value, callback) => {
      if (this.ruleForm2.newPass2 !== '') {
        this.$refs.ruleForm2.validateField('newPass2');
      }
      callback();
    };
    var validatePass2 = (rule, value, callback) => {
      if (value !== this.ruleForm2.newPass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      title: "密码修改",
      ruleForm2: {
        oldPass: "",
        newPass: "",
        newPass2: ""
      },
      cardStyle: { position: "relative", padding: "0px", height: "315px" },
      loading: false,
      isAttribute: {
        isColor: "#FFF",
        isPadding: "10px"
      },
      rules: {
        oldPass: [
          { required: true, message: '旧密码不能为空', trigger: 'blur' },
          { min: 4, max: 20, message: '密码长度为4-20位', trigger: 'blur' }
        ],
        newPass: [
          { required: true, message: '新密码不能为空', trigger: 'blur'},
          { min: 6, max: 20, message: '新密码长度为6-20位', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ],
        newPass2: [
          { required: true, message: '确认密码不能为空', trigger: 'blur'},
          { min: 6, max: 20, message: '确认密码长度为6-20位', trigger: 'blur' },
          { validator: validatePass2, trigger: 'blur' }
        ]
      }
    };
  },
  
  methods: {
    ...mapMutations(['setMenuIndex']),
    clearLoading() {
      this.loading = false;
    },
    submitForm(formName) {
      let validate = true;
      this.$refs.ruleForm2.validate((valid) => {
        if (!valid) {
          validate = false;
          return false;
        }
      });
      if(validate)
        this.updatePass();
    },
    resetForm() {
    //   this.setMenuIndex('admin')
    //   this.$router.push('/admin')
    },
    updatePass() {
      this.loading = true;
      let userId = JSON.parse(sessionStorage.getItem('byUserInfo'))
      let params = {
        password: this.ruleForm2.oldPass,
        newPassword: this.ruleForm2.newPass,
        userId: userId.id
      };
      this.$API.setUsePasswordUpdate(params).then( res => {
        this.loading = false;
        if ( res.code === 0) {
          this.$message.success("修改成功");
          this.$router.push("/admin");
        } else {
          this.$message.warning(res.msg)
        }
       
      })
    }
  }
};
</script>

<style lang="scss">
.editPassword{
    height: calc(100vh - 100px);
    margin: 20px;
    background: #fff;
    border-radius: 6px;
    .self-con{
        padding: 20px;
        .password-title{
            font-size: 22px;
            padding: 40px 0;
            color: #313233;
            font-weight: 600;
        }
        .password-form{
            .el-input{
                width: 400px;
            }
        }
        .btn{
            padding: 20px 0;
            .el-button{
                width: 100px;
            }
        }
    }
}
</style>
